<script>
import { layoutComputed } from "@/state/helpers";
import appConfig from "../../app.config";

export default {
  components: {},
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      authConfig: appConfig.auth,
      userAuths: [],
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                let aChild = siblingCollapse.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                let aChild = item1.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }

    // 解析user auth
    let userJSON = JSON.parse(localStorage.getItem("userdata"));
    this.userAuths = userJSON.auth_ids;
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 0);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <ul class="navbar-nav h-100" id="navbar-nav">
      <li class="nav-item">
        <router-link class="nav-link menu-link" to="/">
          <i class="ri-dashboard-2-line"></i>
          <span data-key="t-dashboards">{{ $t("t-dashboards") }}</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="userAuths.indexOf(authConfig.pages) != -1">
        <a
          class="nav-link menu-link"
          href="#sidebarPages"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="sidebarPages"
        >
          <i class="ri-layout-3-line"></i>
          <span data-key="t-pages"> {{ $t("t-pages") }}</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarPages">
          <ul class="nav nav-sm flex-column">
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.pages_group.home) != -1"
            >
              <router-link to="/home" class="nav-link" data-key="t-home">
                {{ $t("t-home") }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.pages_group.about) != -1"
            >
              <router-link to="/about" class="nav-link" data-key="t-about">
                {{ $t("t-about") }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.pages_group.contact) != -1"
            >
              <router-link to="/contact" class="nav-link" data-key="t-contact">
                {{ $t("t-contact") }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.pages_group.news) != -1"
            >
              <router-link to="/news" class="nav-link" data-key="t-news">
                {{ $t("t-news") }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.pages_group.faq) != -1"
            >
              <router-link to="/faq" class="nav-link" data-key="t-faq">
                {{ $t("t-faq") }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.pages_group.award) != -1"
            >
              <router-link to="/award" class="nav-link" data-key="t-award">
                {{ $t("t-award") }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.pages_group.product) != -1"
            >
              <router-link to="/product" class="nav-link" data-key="t-product">
                {{ $t("t-product") }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.pages_group.article) != -1"
            >
              <router-link to="/article" class="nav-link" data-key="t-article">
                {{ $t("t-article") }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item" v-if="userAuths.indexOf(authConfig.auth) != -1">
        <a
          class="nav-link menu-link"
          href="#sidebarAuth"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="sidebarAuth"
        >
          <i class="ri-account-circle-line"></i>
          <span data-key="t-auth"> {{ $t("t-auth") }}</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarAuth">
          <ul class="nav nav-sm flex-column">
            <li
              class="nav-item"
              v-if="userAuths.indexOf(authConfig.auth_group.account) != -1"
            >
              <router-link to="/account" class="nav-link" data-key="t-account">
                {{ $t("t-account") }}
              </router-link>
            </li>
            <!-- IP管理 -->
            <!-- <li class="nav-item" v-if="userAuths.indexOf(authConfig.auth_group.ip) != -1">
              <router-link to="/ip" class="nav-link" data-key="t-ip">
                {{ $t("t-ip") }}
              </router-link>
            </li> -->
          </ul>
        </div>
      </li>

      <li class="nav-item" v-if="userAuths.indexOf(authConfig.company) != -1">
        <router-link class="nav-link menu-link" to="/company">
          <i class="ri-file-list-3-line"></i>
          <span data-key="t-company">{{ $t("t-company") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
