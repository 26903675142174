<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col text-center">
          Copyright © {{ new Date().getFullYear() }} 新澄資訊有限公司 版權所有
        </div>
      </div>
    </div>
  </footer>
</template>
